var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "authorizationManagement", staticClass: "authorization-management" },
    [
      _c("div", { staticClass: "left" }, [
        _c(
          "p",
          { staticClass: "search" },
          [
            _c(
              "el-input",
              {
                staticClass: "input-with-select",
                attrs: { placeholder: "角色名称" },
                model: {
                  value: _vm.keyword,
                  callback: function($$v) {
                    _vm.keyword = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "keyword"
                }
              },
              [
                _c("el-button", {
                  attrs: {
                    slot: "append",
                    size: "small",
                    type: "primary",
                    icon: "el-icon-search"
                  },
                  on: { click: _vm.searchUser },
                  slot: "append"
                })
              ],
              1
            )
          ],
          1
        ),
        _c("div", { staticClass: "user-list" }, [
          _c(
            "ul",
            { ref: "userList" },
            _vm._l(_vm.userList, function(user, index) {
              return _c(
                "li",
                {
                  key: user.id,
                  class: { active: index == 0 },
                  on: {
                    click: function($event) {
                      return _vm.onUserClick(user, $event)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(user.roleName) + " ")]
              )
            }),
            0
          )
        ])
      ]),
      _c("div", { staticClass: "right" }, [
        _c(
          "p",
          { staticClass: "title" },
          [
            _c("span", [_vm._v("操作权限列表")]),
            _vm.$store.state.menu.nowMenuList.indexOf("权限配置") >= 0
              ? _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "small",
                      loading: _vm.editLoading
                    },
                    on: { click: _vm.onSave }
                  },
                  [_vm._v(_vm._s(_vm.isEditVisible ? "保存" : "权限配置"))]
                )
              : _vm._e()
          ],
          1
        ),
        _c(
          "div",
          { ref: "systemList", staticClass: "system-list" },
          _vm._l(_vm.tableData, function(auth, key) {
            return _c(
              "a",
              {
                key: key,
                class: { active: key == 0 },
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function($event) {
                    return _vm.onSystemClick(auth.id, $event)
                  }
                }
              },
              [_vm._v(" " + _vm._s(auth.menuName))]
            )
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "table-box" },
          _vm._l(_vm.tableData, function(auth) {
            return _c("auth-table", {
              key: auth.id,
              ref: "authTable",
              refInFor: true,
              attrs: {
                id: "table" + auth.id,
                data: auth.subMenuTrees,
                menuName: auth.menuName,
                tableHeight: _vm.tableHeight,
                isEditVisible: _vm.isEditVisible
              },
              on: { getMenuIds: _vm.getMenuIds }
            })
          }),
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }