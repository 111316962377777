
<template>
  <div class="auth-table">
    <p>{{ menuName }}</p>

    <el-table
      :data="tableData"
      :span-method="objectSpanMethod"
      border
      :height="tableHeight"
    >
      <el-table-column
        prop="serialNumber"
        label="序号"
        width="80"
      ></el-table-column>
      <el-table-column label="一级菜单">
        <template slot-scope="scope">
          <el-checkbox
            :label="scope.row.Lv1Menu"
            v-model="scope.row.Lv1MenuChecked"
            :disabled="!isEditVisible"
            @change="onLv1Change(scope.row, true)"
          ></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column label="二级菜单">
        <template slot-scope="scope">
          <el-checkbox
            v-if="scope.row.Lv2MenuId"
            :label="scope.row.Lv2Menu"
            :disabled="!isEditVisible"
            v-model="scope.row.Lv2MenuChecked"
            @change="onLv2Change(scope.row, true)"
          ></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-checkbox
            v-for="operate in scope.row.operateList"
            :label="operate.label"
            v-model="operate.checked"
            :disabled="!isEditVisible"
            :key="operate.id"
            @change="onLv3Change(scope.row, true, operate.checked)"
          ></el-checkbox>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: function (params) {
        return []
      }
    },
    tableHeight: {
      type: Number
    },
    menuName: {
      type: String
    },
    isEditVisible: {
      type: Boolean
    }
  },
  data() {
    return {
      tableData: [],
      spanArr: [],
      pos: null
    }
  },
  methods: {
    /**表格合并 */
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0 || columnIndex === 1) {
        const _row = this.spanArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },
    getSpanArr(data) {
      this.spanArr = []
      for (let i = 0; i < data.length; i++) {
        if (i === 0) {
          this.spanArr.push(1)
          this.pos = 0
        } else {
          // 判断当前元素与上一个元素是否相同
          if (data[i].id === data[i - 1].id) {
            this.spanArr[this.pos] += 1
            this.spanArr.push(0)
          } else {
            this.spanArr.push(1)
            this.pos = i
          }
        }
      }
    },
    /**从上到下拼装树数据 */
    tree2Data(tree) {
      let res = []
      if (tree && tree.length !== 0) {
        tree.forEach((node, index) => {
          let obj = { serialNumber: index + 1 }
          obj.id = node.id
          obj.Lv1Menu = node.menuName
          obj.Lv1MenuChecked = node.checked === 1
          // 遍历二级菜单
          if (node.subMenuTrees && node.subMenuTrees.length !== 0) {
            node.subMenuTrees.forEach((child) => {
              obj.Lv2Menu = child.menuName
              obj.Lv2MenuChecked = child.checked === 1
              obj.Lv2MenuId = child.id
              // 遍历操作权限
              obj.operateList = []
              if (child.subMenuTrees && child.subMenuTrees.length !== 0) {
                child.subMenuTrees.forEach((el, index) => {
                  let o = {
                    id: el.id,
                    label: el.menuName,
                    checked: el.checked === 1,
                    visible: el.checked === 1
                  }
                  obj.operateList.push(o)
                })
              }
              res.push(JSON.parse(JSON.stringify(obj)))
            })
          } else {
            res.push(obj)
          }
        })
      }
      return res
    },
    /**一级菜单选中 */
    onLv1Change(row, flag) {
      if (flag) {
        this.tableData.forEach((item) => {
          if (item.id === row.id) {
            // 全选反选
            if (!row.Lv1MenuChecked) {
              item.Lv2MenuChecked = false
              item.Lv1MenuChecked = false
              if (item.operateList && item.operateList.length !== 0) {
                item.operateList.forEach((operate) => {
                  operate.checked = false
                })
              }
            } else {
              item.Lv2MenuChecked = true
              item.Lv1MenuChecked = true
              if (item.operateList && item.operateList.length !== 0) {
                item.operateList.forEach((operate) => {
                  operate.checked = true
                })
              }
            }
          }
        })
      }
    },
    /**二级菜单选中 */
    onLv2Change(row, flag) {
      // 全选反选
      if (flag) {
        if (!row.Lv2MenuChecked) {
          if (row.operateList && row.operateList.length !== 0) {
            row.operateList.forEach((operate) => {
              operate.checked = false
            })
          }
        } else {
          if (row.operateList && row.operateList.length !== 0) {
            row.operateList.forEach((operate) => {
              operate.checked = true
            })
          }
          this.tableData.forEach((item) => {
            if (item.id === row.id) {
              item.Lv1MenuChecked = true
            }
          })
        }
      } else {
      }
    },
    /**操作菜单选中 */
    onLv3Change(row, flag, operate) {
      if (flag) {
        if (operate) {
          row.Lv2MenuChecked = true
          this.tableData.forEach((item) => {
            if (item.id === row.id) {
              item.Lv1MenuChecked = true
            }
          })
        }
      }
    },
    // 获取选中权限id
    getCheckedNodes() {
      let menuIds = []
      if (this.tableData && this.tableData.length !== 0) {
        this.tableData.forEach((data) => {
          if (data.Lv1MenuChecked) {
            menuIds.push(data.id)
          }
          if (data.Lv2MenuChecked) {
            menuIds.push(data.Lv2MenuId)
          }
          if (data.operateList && data.operateList.length !== 0) {
            data.operateList.forEach((operate) => {
              if (operate.checked) {
                if (operate.id === '115') {
                }
                menuIds.push(operate.id)
              }
            })
          }
        })
      }
      this.$emit('getMenuIds', menuIds)
    },
    /**处理表格数据 */
    getTableData() {
      const data = this.tree2Data(this.data)
      this.tableData = data
      this.getSpanArr(this.tableData)
    }
  },
  created() {},
  mounted() {
    this.getTableData()
  },
  watch: {
    data: function (newval, oldval) {
      this.getTableData()
    }
  }
}
</script>

<style lang="scss" scoped>
.auth-table {
  @include themify() {
    p {
      font-size: 14px;
      color: themed('n7');
      padding: 10px;
    }
  }
}
</style>
